@use "./src/themes/generated/variables.dark.scss" as dark;
@use "./src/themes/generated/variables.light.scss" as light;

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	//font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial, sans-serif;
}

ul {
	padding-inline-start: 40px;
}

html,
body {
	width: 100%;
	height: 100%;
	display: flex;
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 200 !important;
}

// .pageToolbar {
// 	margin-bottom: 20px;
// }
// .tabContent {
// 	margin-top: 30px;
// }

// .dx-tabs {
// 	background-color: transparent !important;
// 	border-bottom: 1px solid light.$base-border-color !important;
// 	.dx-tab {
// 		background-color: transparent !important;
// 		.dx-icon {
// 			display: inline-block !important;
// 			margin-right: 10px;
// 		}
// 	}
// }


.bordered {
	border-style: solid;
	border-width: 0;
}

.description {
	opacity: 0.6;
	font-size: inherit !important;
}

.dx-swatch-tw2-dark {

	.success {
		color: dark.$base-success
	}

	.warning {
		color: dark.$base-warning
	}

	.danger {
		color: dark.$base-danger
	}

	.panel {
		background-color: dark.$base-bg;
	}

	.faded {
		//		background-color: #f1f5f9;
	}

	.selected {
		background-color: adjust-color(dark.$base-accent, $alpha: -0.9);
		border-color: dark.$base-accent !important;

		.icon,
		.title {
			opacity: 1 !important;
			color: light.$base-accent;
		}
	}

	.bordered {
		border-color: dark.$base-border-color;
	}

}

.dx-swatch-tw2-light {


	.success {
		color: light.$base-success
	}

	.warning {
		color: light.$base-warning
	}

	.danger {
		color: light.$base-danger
	}

	.panel {
		background-color: light.$base-bg;
	}

	.dx-popup-fullscreen {
		.dx-popup-content {
			background-color: #f1f5f9;
		}
	}

	.faded {
		background-color: #f1f5f9;
	}

	.selected {
		background-color: adjust-color(light.$base-accent, $alpha: -0.9);
		border-color: light.$base-accent !important;

		.icon,
		.title {
			opacity: 1 !important;
			color: light.$base-accent;
		}
	}

	.bordered {
		border-color: light.$base-border-color !important;
	}
}

// .dx-treeview {
// 	.dx-treeview-toggle-item-visibility {
// 		height: 44px;
// 	}

// 	.dx-treeview-item {
// 		display: flex !important;
// 		align-items: center;
// 		padding: 4px 8px !important;

// 		.dx-treeview-item-content {
// 			flex: 1;
// 		}
// 	}
// }

.dx-fileuploader-container {
	width: auto !important;
}

.dx-tag-content-no-delete {
	padding: 3px 8px !important;
}

.dx-toolbar:not(.dx-popup-title) {
	background-color: transparent !important;

	.dx-toolbar-items-container {
		height: 100% !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;

		.dx-toolbar-before,
		.dx-toolbar-center,
		.dx-toolbar-after {
			position: relative;
			height: auto !important;
			display: flex;
			align-items: flex-end;

			.dx-item-content {
				display: flex;
				flex-direction: column;

				&>div:first-child:not([class]) {
					font-size: 70%;
				}
			}
		}
	}
}

.dx-drawer-wrapper {

	.dx-drawer-panel-content,
	.dx-drawer-content {
		display: flex;
	}

	.dx-drawer-content {
		flex-direction: column;
	}

}

.dx-popup-fullscreen {
	.dx-popup-content {
		padding: 0 !important;
	}
}


.dx-multiview-item-content,
.dx-popup-content,
.dx-drawer-content {
	display: flex !important;
	flex-direction: column;
}

.dx-field-item-help-text {
	white-space: pre !important;
}

.dx-dropdownbutton,
.dx-button {
	&.compact {
		border: 0;
		//border: 1px solid red !important;

		.dx-button-content {
			padding: 0 !important;
		}
	}
}