$base-font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
$base-accent: #662e9b;
$base-label-color: #dedede;
$base-link-color: #662e9b;
$base-icon-color: #dedede;
$base-text-color: #dedede;
$base-bg: #0f172a;
$base-border-color: #4d4d4d;
$base-border-radius: 6px;
$base-success: #4aa23c;
$base-warning: #f0ad4e;
$base-danger: #f33924;
$base-hover-color: #dedede;
$base-hover-bg: #ffffff0d;
$base-focus-color: #2a2a2a;
$base-focus-bg: #662e9b;
$base-invalid-color: #f33924;
$base-invalid-faded-border-color: #f3392466;
$button-normal-color: #dedede;
$button-normal-bg: #0f172a;
$button-normal-border-color: #4d4d4d;
$button-normal-contained-bg-selected: #263a6a;
$button-normal-outlined-bg-selected: #ffffff2b;
$button-normal-text-bg-selected: #ffffff2b;
$button-default-color: #ffffff;
$button-default-bg: #662e9b;
$button-default-border-color: #ffffff;
$button-default-contained-bg-selected: #9254cc;
$button-default-outlined-bg-selected: #662e9b4d;
$button-default-text-bg-selected: #662e9b4d;
$button-danger-color: #ffffff;
$button-danger-bg: #f33924;
$button-danger-border-color: #f0240d;
$button-danger-contained-bg-selected: #f88376;
$button-danger-outlined-bg-selected: #f339244d;
$button-danger-text-bg-selected: #f339244d;
$button-success-color: #ffffff;
$button-success-bg: #4aa23c;
$button-success-border-color: #428f35;
$button-success-contained-bg-selected: #79c96c;
$button-success-outlined-bg-selected: #4aa23c4d;
$button-success-text-bg-selected: #4aa23c4d;
$list-border-color: #4d4d4d;
$list-group-color: #dedede;
$list-group-header-bg: #1824420d;
$list-normal-color: #dedede;
$list-item-hover-bg: #ffffff0d;
$list-item-selected-bg: #ffffff1f;
$list-item-focused-selected-bg: #662e9bb3;
$list-focused-bg: #662e9b;
$list-item-active-bg: #662e9b;
$fieldset-field-label-color: #dedede;
$checkbox-border-color: #4d4d4d;
$checkbox-border-color-focused: #662e9b;
$checkbox-bg: #0f172a;
$checkbox-hover-border-color: #8540c6;
$checkbox-active-icon-bg: #9cafdc33;
$scrollable-scroll-bg: #314a88b3;
$scrollable-scrollbar-active-bg: #314a8833;
$splitter-resize-handle-color: #dedede;
$splitter-resize-handle-color-hover: #dedede;
$splitter-resize-handle-color-focused: #2a2a2a;
$splitter-resize-handle-bg: #ffffff14;
$splitter-resize-handle-bg-hover: #ffffff3d;
$splitter-resize-handle-bg-focused: #662e9b;
$switch-border-color: #4d4d4d;
$switch-on-color: #dedede;
$switch-container-active-bg: #9cafdc33;
$switch-off-color: #787878;
$tabs-tab-color: #dedede;
$tabs-tab-selected-color: #dedede;
$tabs-tab-bg: #131d35;
$tabs-tab-selected-bg: #0f172a;
$tabs-border-color: #4d4d4d;
$tabs-focused-border-color: #662e9b;
$tabs-tab-hover-bg: #0f172a;
$badge-color: #2a2a2a;
$badge-bg: #662e9b;
$overlay-content-bg: #0f172a;
$overlay-shader-bg: #0f172acc;
$overlay-content-border-radius: 8px;
$textbox-search-icon-color: #787878;
$texteditor-color: #dedede;
$texteditor-placeholder-color: #787878;
$texteditor-bg: #0f172a;
$texteditor-filled-bg: #dedede0d;
$texteditor-border-color: #4d4d4d;
$texteditor-focused-border-color: #662e9b;
$texteditor-hover-border-color: #662e9b66;
$dropdowneditor-button-hover-bg: #020204;
$dropdowneditor-button-active-bg: #000000;
$dropdowneditor-icon-color: #dedede;
$dropdowneditor-icon-active-color: #dedede;
$dropdowneditor-icon-border-radius: 5px;
$numberbox-spin-active-bg: #000000;
$numberbox-spin-hover-border-color: transparent;
$numberbox-spin-icon-color: #dedede;
$calendar-color: #dedede;
$calendar-header-color: #dedede;
$calendar-cell-other-color: #dedede61;
$calendar-bg: #0f172a;
$calendar-hover-bg: #662e9b3d;
$calendar-cell-selected-bg: #662e9b;
$calendar-cell-active-bg: #662e9b66;
$loadindicator-bg: #b1b1b1;
$treeview-focused-bg: #662e9b;
$treeview-hover-bg: #ffffff0d;
$treeview-border-color: #4d4d4d;
$treeview-item-selected-color: #dedede;
$treeview-spin-icon-color: #dedede;
$menu-popup-bg: #0f172a;
$menu-item-hover-bg: #ffffff0d;
$menu-color: #dedede;
$menu-item-hovered-color: #dedede;
$menu-item-expanded-color: #dedede;
$menu-item-selected-bg: #1c2c50;
$menu-popup-border-color: #4d4d4d;
$selectbox-list-bg: #0f172a;
$toolbar-bg: #0f172a;
$popup-title-bg: transparent;
$tileview-color: #dedede;
$toast-color: #ffffff;
$toast-info-bg: #662e9b;
$toast-warning-bg: #f0ad4e;
$toast-error-bg: #f33924;
$toast-success-bg: #4aa23c;
$progressbar-bg: #21335d;
$progressbar-range-bg: #662e9b;
$tooltip-color: #dedede;
$tooltip-bg: #0f172a;
$tooltip-border-color: #4d4d4d;
$slider-bar-bg: #4d4d4d;
$slider-range-bg: #662e9b;
$gallery-indicator-bg: #0f172a;
$gallery-indicator-item-selected-bg: #662e9b;
$gallery-indicator-focused-bg: #8c4cca;
$gallery-navbutton-hover-color: #662e9b80;
$gallery-nav-arrow-color: #2a2a2a;
$gallery-navbutton-active-color: #662e9bb3;
$loadpanel-content-bg: #0f172a;
$tagbox-tag-color: #dedede;
$tagbox-tag-bg: #21335d;
$tagbox-tag-button-remove-bg: #3c5ca8;
$radiogroup-checked-bg: #662e9b;
$radiobutton-active-bg: #00000033;
$accordion-color: #dedede;
$accordion-title-color: #dedede;
$accordion-item-title-opened-bg: transparent;
$accordion-item-border-color: #4d4d4d;
$accordion-item-focused-border-color: #662e9b;
$accordion-title-active-color: #dedede;
$accordion-item-hover-bg: #ffffff0d;
$colorbox-overlay-bg: #0f172a;
$datagrid-base-color: #dedede;
$datagrid-base-background-color: #0f172a;
$datagrid-border-color: #4d4d4d;
$datagrid-columnchooser-item-color: #9c9c9c;
$datagrid-columnchooser-font-weight: normal;
$datagrid-drag-header-border-color: #662e9b80;
$datagrid-selection-bg: #444444;
$datagrid-row-selected-border-color: #4d4d4d;
$datagrid-row-selected-color: #dedede;
$datagrid-row-focused-color: #dedede;
$datagrid-row-focused-bg: #201c41;
$datagrid-hover-bg: #ffffff0d;
$datagrid-menu-icon-color: #888888;
$datagrid-cell-modified-border-color: #4aa23c80;
$datagrid-row-invalid-faded-border-color: #f3392466;
$datagrid-nodata-color: #dedede;
$datagrid-group-row-color: #efefef;
$datagrid-group-row-bg: #131d35;
$datagrid-search-color: #2a2a2a;
$datagrid-spin-icon-color: #7c7c7c;
$datagrid-search-bg: #662e9b;
$datagrid-row-error-color: #dedede;
$datagrid-row-error-bg: #982b26;
$datagrid-link-color: #662e9b;
$datagrid-focused-border-color: #552984;
$datagrid-editor-bg: #0f172a;
$datagrid-columnchooser-bg: #0f172a;
$datagrid-row-alternation-bg: #141f39;
$datagrid-summary-color: #dededeb3;
$pager-page-selected-color: #dedede;
$pager-page-selected-bg: #263a6a;
$pivotgrid-area-color: #929292;
$pivotgrid-totalcolor: #121c33;
$pivotgrid-grandtotalcolor: #ffffff0d;
$pivotgrid-field-area-text-color: #d4d4d4;
$pivotgrid-spin-icon-color: #7d7d7d;
$fileuploader-filename-color: #dedede;
$fileuploader-falename-status-color: #787878;
$fileuploader-border-color: #4d4d4d;
$scheduler-base-border-color: #4d4d4d99;
$scheduler-accent-border-color: #666666;
$scheduler-appointment-text-color: #2a2a2a;
$scheduler-appointment-base-color: #662e9b;
$scheduler-appointment-start-color: #0000004d;
$scheduler-first-month-border-color: #808080;
$scheduler-workspace-focused-cell-color: #a673d5;
$scheduler-current-time-cell-color: #662e9b;
$scheduler-time-indicator-color: #8c4cca;
$form-field-item-color: #dedede;
$form-group-border-color: #4d4d4d;
$filterbuilder-text-color: #000000;
$filterbuilder-text-focus-color: #000000;
$filterbuilder-plus-icon-color: #4aa23ccc;
$filterbuilder-remove-icon-color: #f33924cc;
$filterbuilder-group-operation-color: #f33924cc;
$filterbuilder-item-field-color: #662e9bcc;
$filterbuilder-item-operator-color: #4aa23ccc;
$filterbuilder-item-value-color: #cccccccc;
$filterbuilder-menu-icon-color: #dedede;
$drawer-shader-background-color: #00000080;
